import { Override } from "../types";
import { timeZoneToZoneId } from "./api.mutators";
import { ZoneId } from "./client";
import { OneOnOneOptions } from "./OneOnOnes";

export const oneOnOneOptionsToRequestData = <T extends OneOnOneOptions>(
  options: T
): Override<T, { instances?: boolean; viewerTimezone?: ZoneId }> => ({
  ...options,
  viewerTimezone: options.viewerTimeZone && timeZoneToZoneId(options.viewerTimeZone),
});
